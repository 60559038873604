var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('div',[_c('div',{staticClass:"robot-box"},[_c('label',{staticClass:"ml-1 checkbox-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isNotARobot),expression:"isNotARobot"}],staticClass:"custom-checkbox",attrs:{"type":"checkbox","disabled":_vm.isCaptchaVerified},domProps:{"checked":Array.isArray(_vm.isNotARobot)?_vm._i(_vm.isNotARobot,null)>-1:(_vm.isNotARobot)},on:{"change":[function($event){var $$a=_vm.isNotARobot,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isNotARobot=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isNotARobot=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isNotARobot=$$c}},_vm.handleCheckboxChange]}}),_vm._v(" I am not a robot "),_vm._m(0)])])]),(!_vm.isDefalutPosq)?_c('div'):_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":_vm.showLabel && _vm.isDefalutPosq ? 4 : 0}}),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPosq
      ?
      12
      :
      24}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recaptcha-img"},[_c('img',{attrs:{"src":require("@/assets/img/icons/recaptcha.svg"),"alt":"icon","width":"50","height":"45"}})])
}]

export { render, staticRenderFns }